<template>
  <div>
     {{ formattedDate }}
  </div>
</template>
<script>
import {DATE_DASH_TIME} from "@/utils/date";

export default {
  props: ['date'],
  computed: {
    formattedDate() {
      return this.$moment.utc(this.date).tz(this.$store.getters["auth/currentClub"].timezone).format(DATE_DASH_TIME);
    }
  }
}
</script>
